import {
    getFavorites,
    getPasswordPolicies,
    getTree,
    getUserData,
    getUserPermissions,
    postActionLogs,
    verifyEmail,
} from '../services/python-services';
import Notiflix from 'notiflix';
import { initialContextState } from '../context/RMContext';
import { useReducer } from 'react';

export const getEnvironment = (URL: string) => {
    if (URL.includes('dev') || URL.includes('localhost') || URL.includes('pr-')) {
        return 'dev';
    } else if (URL.includes('staging')) {
        return 'staging';
    } else if (URL.includes('qa')) {
        return 'qa';
    } else if (URL.includes('rm')) {
        return 'prod';
    }
    return 'dev';
};
export const formatterDate = (date: Date) => {
    const year = date.getFullYear();
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = date.getDate();
    return `${year}/${month}/${day}`;
};

export const formatterStringToDate = (date_str: string) => {
    const date = new Date(date_str);
    const year = date.getFullYear();
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = date.getDate();
    return `${year}-${month}-${day}`;
};

export const findLongString = (s: string[]) => {
    return Math.max.apply(
        null,
        s.map((w: string) => w.length)
    );
};

export const convertArrayString = (s: string[]) => {
    const r = s.map((w: string) => (w !== null && w.length > 22 ? w.substring(0, 15) : w));
    return r;
};
export function Commas(x: string | number) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const arrayIncludes = (data: string[], value: string) => {
    return data.includes(value);
};

export const getTreeData = async (setUserData: any, userData: any) => {
    const treemap = await getTree();
    if (treemap?.length > 0) {
        const old_tree = treemap[0].children?.some((item: any) => item.name === "old_tree") ? treemap[0].children?.filter((item: any) => item.name === "old_tree")[0]?.children : [];
        const new_tree = treemap[0].children?.some((item: any) => item.name === "new_tree") ? treemap[0].children?.filter((item: any) => item.name === "new_tree")[0]?.children : [];
        addSiteMapToContext(setUserData, userData, old_tree);
        addNewTreeToContext(setUserData, userData, new_tree);
        const favorites = findFavoriteItems(old_tree);
        addFavoritesToContext(setUserData, userData, favorites);
    }
};

// export const getFavoritesData = async (setUserData: any, userData: any) => {
//     const favorites = await getFavorites();
//     addFavoritesToContext(setUserData, userData, favorites);
// }

export const getUserPermissionsData = async (setUserData: any, userData: any) => {
    const userPermissions = await getUserPermissions();
    let viewAccess: any[] = [];
    let roles: any[] = [];
    userPermissions.map((permission: any) => {
        viewAccess.push(...permission.views);
        roles.push(permission.id);
    });
    addUserPermissionsToContext(setUserData, userData, { viewAccess: viewAccess, roles: roles });
    updateIsSuperUserToContext(
        setUserData,
        userData,
        validateIsSuperUser({ viewAccess: viewAccess, roles: roles })
    );
    updateCanDownloadToContext(
        setUserData,
        userData,
        validateUserDownloadReports({ viewAccess: viewAccess, roles: roles })
    );
    updateManageReportsToContext(
        setUserData,
        userData,
        validateUserManageReport({ viewAccess: viewAccess, roles: roles })
    );
};

export function generateFirstNodePath(node: any, path: string = ''): string {
    // Add current node to the path
    const currentPath = path ? `${path}/${node.name}` : node.name;

    // If node has children, recursively call the function for the first child only
    if (node.children && node.children.length > 0) {
        return generateFirstNodePath(node.children[0], currentPath);
    } else {
        // If node has no children, return the path
        return currentPath;
    }
}
export const executeLoginProcess = async (
    token: string,
    setUserData: any,
    userData: any,
    navigate: any,
    isLoginCall = true
) => {
    await localStorage.setItem('tokenkey', token);
    localStorage.setItem('login', 'true');
    try {
        await getDataToFillContext(setUserData, userData);
        const currentView = window.location.href.split('/').slice(-1)[0];
        if (currentView === 'login' || currentView === 'passwordless') registerViewLog(11); // 11 is the views_View id corresponding to the login
        const { sitemap } = userData;
        const firstPath = generateFirstNodePath(sitemap[0]);
        navigate('/' + firstPath);
    } catch (error) {
        // Handle errors
        console.error('executeLoginProcess', error);
        returnToLogin(navigate, setUserData, true, isLoginCall);
    }
};

export const executeSSOLoginProcess = async (
    token: string
    // setUserData: any,
    // userData: any,
    // navigate: any,
    // isLoginCall = true
) => {
    await localStorage.setItem('tokenkey', token);
    //localStorage.setItem('login', 'true');
    try {
        // await getDataToFillContext(setUserData, userData);
        const currentView = window.location.href.split('/').slice(-1)[0];
        if (currentView === 'login' || currentView === 'passwordless') registerViewLog(11); // 11 is the views_View id corresponding to the login
        // const { sitemap } = userData;
        // const firstPath = generateFirstNodePath(sitemap[0]);
        // navigate('/' + firstPath);
    } catch (error) {
        // Handle errors
        console.error('executeLoginProcess', error);
        // returnToLogin(navigate, setUserData, true, isLoginCall);
    }
};
export const getDataToFillContext = async (setUserData: any, userData: any) => {
    const userDataInfo = await getUserData();
    // console.log("get user data to fill context", userDataInfo)
    if (userDataInfo === undefined) {
        returnToLogin(setUserData, false, false, false);
    }
    addUserDataToContext(setUserData, userData, userDataInfo);
    const authOptions = await verifyEmail({ email: userDataInfo.email });
    addAuthOptionsToContext(setUserData, userData, authOptions);
    const promise1 = getUserPermissionsData(setUserData, userData);
    //get the site map in /tree service
    const promise2 = getTreeData(setUserData, userData);

    await promise1;
    await promise2;
    //getFavoritesData(setUserData, userData);
};
export const LogOut = async () => {
    clearLocalStorage();
    window.location.href = '/login';
    // await returnToLogin(navigate, setUserData, false, false, true);
};
export const addUserDataToContext = (setUserData: any, userData: any, data: any) => {
    userData.userData = data;
    setUserData({ ...userData });
};
export const addAuthOptionsToContext = (setUserData: any, userData: any, authOptions: any) => {
    userData.authOptions = authOptions;
    setUserData({ ...userData });
};
export const addSiteMapToContext = (setUserData: any, userData: any, sitemap: any) => {
    userData.sitemap = sitemap;
    setUserData({ ...userData });
};

export const addNewTreeToContext = (setUserData: any, userData: any, newtree: any) => {
    userData.newtree = newtree;
    setUserData({ ...userData });
};

export const addFavoritesToContext = (setUserData: any, userData: any, favorites: any) => {
    userData.favorites = favorites;
    setUserData({ ...userData });
};

export const addUserPermissionsToContext = (
    setUserData: any,
    userData: any,
    userPermissions: any
) => {
    userData.userPermissions = userPermissions;
    setUserData({ ...userData });
};
export const updateAgredmentToContext = (setUserData: any, userData: any, agreement: any) => {
    userData.userData.agreement = agreement;
    setUserData({ ...userData });
};
export const updateIsLocalToContext = (setUserData: any, userData: any, isLocal: boolean) => {
    userData.isLocal = isLocal;
    setUserData({ ...userData });
};
export const returnToLogin = async (
    navigate: any = null,
    setUserData: any = null,
    isLogin: boolean = false,
    isPasswordless: boolean = false,
    isLogout: boolean = false,
    message: string = ''
) => {
    clearLocalStorage();
    if (!isLogout) {
        Notiflix.Notify.failure(
            message !== ''
                ? message
                : isLogin
                    ? 'Invalid OTP'
                    : isPasswordless
                        ? 'Invalid Token'
                        : 'Session expired or your authentication has changed.'
        );
    }
    if (setUserData !== null && typeof setUserData === 'function') setUserData(initialContextState);

    // console.log("navigator",navigate,"message",message);
    if (navigate !== null) navigate('/login');
    else window.location.replace('/login');
};
export const clearLocalStorage = () => {
    localStorage.clear();
};

export const getQueryParams = (search: string): Object => {
    var params = new URLSearchParams(search);

    // Convert the URLSearchParams object to a JSON object
    var queryParams: any = {};
    params.forEach(function (value, key) {
        queryParams[key] = value;
    });
    return queryParams;
};

export const validateUrl = (url: string) => {
    const valid =
        url !== undefined && url !== null && url !== '' && url !== ' ' && url?.includes('/');
    // console.log("validateUrl",valid,"URL",url);
    return valid;
};

export const isSecondeLevel = (node: any): boolean => {
    let response = false;
    if (node.hasOwnProperty('children')) {
        node.children?.forEach((child: any) => {
            if (child.children && child.children?.length > 0) {
                response = true;
                return;
            }
        });
    }
    return response;
};

export const nodeHasMoreItems = (node: any) => {
    if (node.hasOwnProperty('children') && node.children && node.children?.length > 0) {
        return true;
    }
    return false;
};
export const isLastParentWithChildren = (node: any) => {
    if (!node.children || node.children.length === 0) {
        return false;
    }
    let hasChildWithChildren = false;

    for (const child of node.children) {
        const result = isLastParentWithChildren(child);

        if (result) {
            hasChildWithChildren = true;
        }
    }

    return !hasChildWithChildren;
};

export const hasValidValue = (value: string): boolean => {
    return value !== null && value !== undefined && value !== 'null';
};

export const colorByStatus = (status: string): string => {
    return `${status?.toLocaleLowerCase()}-status`;
};

export const isValidId = (id: any) => {
    return id !== null && id !== undefined && id > 0;
};

export const isValidString = (value: string) => {
    let s = value?.trim();
    return s !== null && s !== undefined && s !== '';
};

export const isValidMultiSelect = (values: any) => {
    return values !== null && values !== undefined && values?.length > 0;
};

export const removeDuplicates = (alerts: any) => {
    const uniqueAlerts: { [key: string]: any } = {};

    alerts.forEach((alert: any) => {
        const key = `${alert.latitude},${alert.longitude},${alert.name?.toUpperCase()}`;
        if (!uniqueAlerts[key]) {
            uniqueAlerts[key] = alert;
        }
    });

    return Object.values(uniqueAlerts);
};
export const viewInArrayViews = (view: string, arrayView: any) => {
    //get view in multiple levels array
    //validate in the first level
    let data = arrayView?.find((v: any) => v.name === view);
    if (data) return data;

    //validate in the second level
    for (let i = 0; i < arrayView.length; i++) {
        data = arrayView[i].children?.find((vv: any) => vv.name === view);
        if (data) {
            break;
        }
    }
    if (data) return data;
    //validate in the third level

    for (let i = 0; i < arrayView.length; i++) {
        let parentV = arrayView[i];
        for (var j = 0; j < parentV.children?.length; j++) {
            let childV = parentV.children;
            if (childV?.length > 0) {
                for (var k = 0; k < childV?.length; k++) {
                    let viewInChild = childV[k];
                    data = viewInChild?.children?.find((vv: any) => vv.name === view);
                    if (data) {
                        break;
                    }
                    if (data) {
                        break;
                    }
                }
                if (data) {
                    break;
                }
            }
        }
        if (data) {
            break;
        }
    }
    return data;
};
export const updateCurrentRoueteToContext = (
    setUserData: any,
    userData: any,
    currentRoute: any
) => {
    userData.currentRoute = currentRoute;
    setUserData({ ...userData });
};

export const updatePreferedTreeToContext = (
    setUserData: any,
    userData: any,
    prefered_tree: any
) => {
    userData.userData.prefered_tree = prefered_tree;
    setUserData({ ...userData });
};

export const findDescription = (array: any, value: any) => {
    for (let i = 0; i < array.length; i++) {
        const obj = array[i];

        if (obj.name === value) {
            return obj.description;
        }

        if (obj.children) {
            const description: any = findDescription(obj.children, value);
            if (description) return description;
        }
    }
};

export const addCountriesToContext = (setUserData: any, userData: any, countries: any) => {
    userData.countries = countries;
    setUserData({ ...userData });
};
export const updateIsSuperUserToContext = (setUserData: any, userData: any, isSuperUser: any) => {
    userData.userGlobals.isSuperUser = isSuperUser;
    setUserData({ ...userData });
};
export const updateCanDownloadToContext = (setUserData: any, userData: any, canDownload: any) => {
    userData.userGlobals.canDownload = canDownload;
    setUserData({ ...userData });
};

export const updateManageReportsToContext = (
    setUserData: any,
    userData: any,
    manageReports: any
) => {
    userData.userGlobals.manageReports = manageReports;
    setUserData({ ...userData });
};

export const registerViewLog = async (viewString: any) => {
    if (viewString && typeof viewString === 'number') await postActionLogs(viewString);
};
export const capitalizeWords = (str: string) => {
    return str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase())
        .join(' ');
};

export const findFavoriteItems = (data: any) => {
    const favorites: any[] = [];

    function traverse(items: any) {
        for (const item of items) {
            if (item.favorite === 1) {
                favorites.push(item);
            }
            if (item.children && item.children.length > 0) {
                traverse(item.children);
            }
        }
    }

    traverse(data);
    return favorites;
};

export const getParentUrl = (viewString: string) => {
    const listOfMainViewWithUnderDash = [
        'tmk_cy',
        'e_commerce',
        'glp_v2',
        'weekly_report',
        'war_room',
    ];
    const sv = viewString.split('_');
    if (
        sv.length >= 2 &&
        listOfMainViewWithUnderDash.some((v: any) => v.includes(sv[0] + '_' + sv[1]))
    ) {
        return sv[0] + '_' + sv[1];
    } else return sv[0];
};

export const getChildUrl = (viewString: string) => {
    const listOfMainViewWithUnderDash = [
        'tmk_cy',
        'e_commerce',
        'glp_v2',
        'weekly_report',
        'war_room',
        'glp_dashboard',
    ];
    const sv = viewString.split('_');
    if (
        sv.length > 3 &&
        listOfMainViewWithUnderDash.some((v: any) => v.includes(sv[0] + '_' + sv[1]))
    ) {
        return sv[0] + '_' + sv[1] + '_' + sv[2];
    } else return sv[0] + '_' + sv[1];
};

export const getDifferentContiners = (data: any) => {
    let response = '';
    data?.map((vessel: any) => {
        if (!response.includes(vessel?.cntr_truckno)) {
            response += (response !== '' ? ',' : '') + vessel.cntr_truckno;
        }
    });
    return response;
};

export const validateUserDownloadReports = (userPermissions: any) => {
    return (
        userPermissions?.viewAccess?.some((v: any) => v.name === 'reports_download') ||
        validateIsSuperUser(userPermissions)
    );
};

export const validateUserManageReport = (userPermissions: any) => {
    return (
        userPermissions?.viewAccess?.some((v: any) => v.name === 'reports_management') ||
        validateIsSuperUser(userPermissions)
    );
};

export const validateIsSuperUser = (userPermissions: any) => {
    return userPermissions?.roles?.some((r: any) => r === 1);
};
// export const handleCloseModalById = (id: string) => {
//     const dialog = document.getElementById(id);
//     //@ts-expect-error
//     if (dialog) dialog.close();
// };

export const handleOpenModalById = (setOpenModal: any, id: string) => {
    setOpenModal(true);
    const dialog = document.getElementById(id);
    //@ts-expect-error
    if (dialog) dialog.showModal();
};
export const formatDate = (inputDate: string) => {
    const date = new Date(inputDate);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year} at ${hours}:${minutes}${ampm}`;
};

export const isDateLessThanTowDays = (inputDate: string) => {
    const date = new Date(inputDate);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 2); // Subtract 2 days from the date
    return date < currentDate;
};

export const getSubViewsByType = (sitemap: any, parent: string) => {
    return sitemap.filter((view: any) => view.name === parent)[0]?.children;
};

export const sortArrayByString = (data: any, property: string) => {
    //remove users with empty porperty
    const data_f = data.filter((item: any) => item[property] !== '' && item[property] !== ' ');
    const ordered_array = data_f?.sort((a: any, b: any) =>
        a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0
    );
    return ordered_array;
};

export const formatDateTime = function (date: any) {
    if (!hasValidValue(date) || !isValidString(date)) return '';

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + hours;

    let outDate = [year, month, day].join('-');
    let outTime = [hours, minutes].join(':');
    return outDate + ' ' + outTime;
};

let interceptorError: any;

export const setInterceptorError = (error: any) => {
    interceptorError = error || null;
};

export const getInterceptorError = () => {
    return interceptorError || null;
};

export const cleanString = (input: string) => {
    // Replace initial spaces, newlines, and tabs with a single space
    return input.replace(/^\s+|\s+$/g, '').replace(/\s+/g, '');
};

export const validatePassword = async (password: any) => {
    const res = await getPasswordPolicies();
    const { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase } =
        res;

    let isValid = true;
    let errorMessages = [];

    if (password.length < MinimumLength) {
        isValid = false;
        errorMessages.push(`Password must be at least ${MinimumLength} characters long.`);
    }
    if (RequireLowercase && !/[a-z]/.test(password)) {
        isValid = false;
        errorMessages.push('Password must contain at least one lowercase letter.');
    }
    if (RequireUppercase && !/[A-Z]/.test(password)) {
        isValid = false;
        errorMessages.push('Password must contain at least one uppercase letter.');
    }
    if (RequireNumbers && !/[0-9]/.test(password)) {
        isValid = false;
        errorMessages.push('Password must contain at least one number.');
    }
    if (RequireSymbols && !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        isValid = false;
        errorMessages.push('Password must contain at least one symbol.');
    }
    console.log(errorMessages);
    return { isValid, errorMessages };
};

export const removeDuplicateFunctional = <T,>(arrayObjects: T[], propertyToCheck: keyof T): T[] => {
    const uniqueObject: { [key: string]: T } = {};

    arrayObjects.forEach((item) => {
        const key = item[propertyToCheck] as unknown as string;
        uniqueObject[key] = item;
    });

    return Object.values(uniqueObject);
};
export const getOpenedFilters = (uniques: any) => {
    let of: any = [];

    // Iterate over the entries of the input object
    Object.entries(uniques).forEach(([key, value]) => {
        if (value !== null) {
            // Add the key-value pair to the result object if the value is not null
            of.push(key);
            // of[key] = value;
        }
    });

    return of;
};

export const removeNullOptions = (options: any): any => {
    return options.filter((option: any) => option !== undefined);
}


export const handleCloseModalById = (setOpenModal: any, id: string) => {
    setOpenModal(false);
    const dialog = document.getElementById(id);
    //@ts-expect-error
    if (dialog) dialog.close();
};

export const addDayToDateString = (str: string, days: number) => {
    var date = new Date(str);

    date.setDate(date.getDate() + days);
    // Format the date as mm/dd/yyyy
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const validateSelectRoles = (selectedRoles: any= []) => {
    // Return true immediately if there's a superuser role with id 1
    if (selectedRoles.some((role:any) => role.id === 1)) return true;

    // Check if any role has a non-empty `divisions_str` or `country_codes_str`
    return selectedRoles.some(
        (role:any) => role.divisions_str !== "" || role.country_codes_str !== ""
    );
};



export const reduceColumnsFunction = (columns: any) => {
    const res = columns.reduce((result: any, column: any) => {
        if (column.show === true) {
            result[column.id] = column.name;
        }
        return result;
    }, {});
    return res;
};

export const reduceColumnTypesFunction = (columns: any) => {
    const res = columns.reduce((result: any, column: any) => {
        if (column.show === true && column.cellType !== undefined && column.cellType !== null) {
            result[column.id] = column.cellType;
        }
        return result;
    }, {});
    return res;
};