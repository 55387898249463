import styles from './users-management.module.scss';
import { Table } from '../table/table';
import { useContext, useEffect, useState } from 'react';
import { GenericModal } from '../generic-modal/generic-modal';
import {
    Column,
    CustomButtonGroup,
    Filter,
    GroupDataWithTypesProps,
    UserManagement,
    rolesInterface,
} from '../../utils/interfaces';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
    getAllCompanies,
    getAllRoles,
    getAllCountries,
    getAllUsers,
    disableUser,
    getAllDepartments,
    getAllAuthorizedEmails,
    getAllJobPositions,
    getUserById,
    updateUser,
    createNewUser,
    postActionLogs,
    updateDomain,
    updateCompany,
    updateCountry,
    createDomain,
    createCompany,
    createCountry,
    deleteCompany,
    deleteDomain,
    deleteCountry,
    groupData,
} from '../../services/python-services';
import Multiselect from 'multiselect-react-dropdown';
import { PencilIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { queries_glp, size_page, stylesMultiSelect } from '../../utils/constants';
import Notiflix from 'notiflix';
import {
    colorByStatus,
    formatDateTime,
    hasValidValue,
    isValidId,
    isValidMultiSelect,
    isValidString,
    validateSelectRoles,
    viewInArrayViews,
} from '../../utils/functions';
import { RMContext } from '../../context/RMContext';
import { OptionToggle } from '../option-toggle/option-toggle';
import { FilterButton } from '../filter-button/filter-button';
import { FilterCard } from '../filter-card/filter-card';
export interface UsersManagementProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const UsersManagement = ({}: UsersManagementProps) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [country, setCountry] = useState(1);
    // const [countryID, setCountryID] = useState(-1);
    const [department, setDepartment] = useState('');
    const [email, setEmail] = useState('');
    const [selectDomain, setSelectDomain] = useState(-1);
    const [jobPosition, setJobPosition] = useState('');
    const [roles, setRoles] = useState<any[]>([]);
    const [teamLeader, setTeamLeader] = useState<any[]>([]);
    const [enabled, setEnabled] = useState(0);
    // const [disabledState, setDisabledState] = useState(false);
    const [enabledUser, setEnabledUser] = useState(true);
    const { sitemap } = useContext(RMContext).userData;
    const { userData } = useContext(RMContext);
    const [listOfAllCountries, setListOfAllCountries] = useState<any[]>([]);
    const [listOfAllCompanies, setListOfAllCompanies] = useState<any[]>([]);
    const [intialListOfAllRoles, setInitialListOfAllRoles] = useState<any[]>([]);
    const [listOfAllRoles, setListOfAllRoles] = useState<any[]>([]);
    const [listOfAllDepartments, setListOfAllDepartments] = useState<any[]>([]);
    const [listOfAllAuthorizedEmails, setListOfAllAuthorizedEmails] = useState<any[]>([]);
    const [listOfAllJobPositions, setListOfAllJobPositions] = useState<any[]>([]);
    const [submitSwitch, setSubmitSwitch] = useState(true);
    const [companyID, setCompanyID] = useState(-1);
    const [idsArray, setIdsArray] = useState<number[]>([]);
    const [checkAllSwitch, setCheckAllSwitch] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [allRows, setAllRows] = useState<any[]>([]);
    const [allFilteredRows, setAllFilteredRows] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any[]>();
    const [idPassed, setIdPassed] = useState('');
    const [typePasssed, setTypePassed] = useState('');
    const [downLoader, setDownLoader] = useState<boolean>(false);

    // const [loadedMainData, setLoadedMainData] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    // const [somethingChange, setSomethingChange] = useState(false);
    // const [changedField, setChangedField] = useState("");
    // const [validForm, setValidForm] = useState(false);
    const [isValidCompany, setIsValidCompany] = useState(true);
    const [isValidEmailFieldSpan, setIsValidEmailFieldSpan] = useState(true);
    const [isValidEmailDomainSpan, setIsValidEmailDomainSpan] = useState(true);
    const [isValidFirstNameSpan, setIsValidFirstNameSpan] = useState(true);
    const [isValidLastNameSpan, setIsValidLastNameSpan] = useState(true);
    const [isValidRoleSpan, setIsValidRoleSpan] = useState(true);
    const [isValidCountrySpan, setIsValidCountrySpan] = useState(true);
    const currentView = window.location.href.split('/').slice(-1)[0];
    const [enterValue, setEnterValue] = useState('');
    const [properties, setProperties] = useState<Filter>({
        enabled: null,
    });
    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    //filtering section
    const [executeApply, setExecuteApply] = useState(true);
    const [applySearch, setApplySearch] = useState(true);
    const [customFilters, setCustomFilters] = useState<any>([]);
    const [closedFilters, setClosedFilters] = useState(true);

    const initialUniquesValues = {
        country__name: null,
        company__value: null,
        user_status: null,
        roles__role_name: null,
        job_position__value: null,
        department__value: null,
        // page: 1,
        // size: size_page,
    };
    const [queries, setQueries] = useState<UserManagement>(initialUniquesValues);
    const [uniques, setUniques] = useState<UserManagement>(initialUniquesValues);
    const filtersString = [
        'user_country',
        'company',
        'user_status',
        'roles__role_name',
        'job_position__value',
        'department__value',
    ];

    const clearValues = () => {
        setAllRows([]);
        // setTotalDatas(0);
        setLoadedData(false);
        setClosedFilters(true);
        setIntialStatus();
    };

    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        setLoadedData(false);
        if (nameComponent === 'table') {
            setClosedFilters(true);
        }

        setIntialStatus();
    };
    const setIntialStatus = () => {
        setQueries(initialUniquesValues);
        setUniques(initialUniquesValues);
        setApplySearch(true);
        setExecuteApply(true);
        setSubmitSwitch(true);
        // setSearchText('');
        // setPage(1);
    };

    const applyFilters = (filters: any, type: string = '', apply: boolean = false) => {
        // let filtersN = {...filters, status:filters.user_status}
        // delete filtersN.user_status
        // console.log(`applyFilters ${filters}  ${type}  ${apply}`,filtersN);
        // if(filters.some(f => f)
        if (type === 'reset') {
            resetFilters();
        } else {
            //clearValues();
            const updatedQueries = {
                ...filters,
                // , page: 1, size: size_page
            };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
        setExecuteApply(apply);
        setApplySearch(apply);
        setSubmitSwitch(true);
    };
    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (viewD?.hasOwnProperty('id')) {
            postActionLogs(viewD.id);
        }
    }, [sitemap, currentView]);

    const initialDataCall = async () => {
        // console.log("initialDataCall");
        let newQueries = { ...queries };
        
        const resData: any = await getAllUsers({ ...newQueries });
        const finalData = resData?.map((obj: any) => ({
            company: obj.company_name,
            country: obj.country_name,
            department: obj.department_name,
            email: obj.email,
            id: obj.id,
            status: obj.status,
            last_login: formatDateTime(obj.last_login),
            name: obj.full_name,
            position: obj.jobPosition_name,
            role: obj.roles_name,
            created_by: obj.created_by,
            created_time: formatDateTime(obj.created_time),
            last_edited_time: formatDateTime(obj.last_edited_time),
            last_edited_by: isValidString(obj.last_edited_by) ? obj.last_edited_by : '',
        }));
        setUsersData([...resData]);
        setAllRows([...finalData]);
        setAllFilteredRows([...finalData]);
        // console.log('submitSwitch', submitSwitch);
        if (submitSwitch) setSubmitSwitch(false);
    };

    const getRoles = async () => {
        const rolesList: rolesInterface[] = await getAllRoles();
        return rolesList || [];
    };

    const getDepartments = async () => {
        const departmentsList = await getAllDepartments();
        return departmentsList || [];
    };

    const getAuthorizedEmails = async () => {
        const authorizedEmailsList = await getAllAuthorizedEmails();
        return authorizedEmailsList || [];
    };

    const getCompanies = async () => {
        const companiesList = await getAllCompanies();
        return companiesList || [];
    };
    const getJobPositions = async () => {
        const jobPositionsList = await getAllJobPositions();
        return jobPositionsList || [];
    };

    const getCountries = async () => {
        const countriesList = await getAllCountries();
        return countriesList || [];
    };

    const fetchData = async () => {
        // multiple async calls in a single one request
        const autorizedEmailsL = getAuthorizedEmails();
        const allCompaniesL = getCompanies();
        const allCountriesL = getCountries();
        const rolesListA = getRoles();
        const depA = getDepartments();
        const jpL = getJobPositions();

        // const autorizedEmailsL = getAllAuthorizedEmails();
        // const allCompaniesL = getAllCompanies();
        // const allCountriesL = getAllCountries();
        // const rolesListA = getAllRoles();
        // const depA = getAllDepartments();
        // const jpL = getAllJobPositions();
        await Promise.all([
            autorizedEmailsL,
            allCompaniesL,
            allCountriesL,
            rolesListA,
            depA,
            jpL,
        ]).then((results: any[]) => {
            setListOfAllAuthorizedEmails(results[0]);
            setListOfAllCompanies(results[1]);
            setListOfAllCountries(results[2]);
            setListOfAllRoles(results[3]);
            setInitialListOfAllRoles(results[3]);
            setListOfAllDepartments(results[4]);
            setListOfAllJobPositions(results[5]);
            setLoadedData(true);
        });
    };

    useEffect(() => {
        if (submitSwitch) {
            // setLoadedData(false);
            initialDataCall();
        }
    }, [submitSwitch]);

    useEffect(() => {
        if (!loadedData) {
            fetchData();
        }
    }, [loadedData]);

    // useEffect(() => { }, [loadedData]);

    const selectId = (id: number) => {
        let idsTmp = [...idsArray];
        if (idsTmp.includes(id)) {
            idsTmp = idsTmp.filter((i: number) => i !== id);
        } else {
            idsTmp.push(id);
        }
        setIdsArray([...idsTmp]);
    };

    const selectAll = () => {
        let idsTmp: number[] = [...idsArray];
        if (checkAllSwitch === false) {
            const holder1: number[] = allRows.map((x: any) => x.id);
            idsTmp.push(...holder1);
            setCheckAllSwitch(true);
        } else {
            idsTmp = [];
            setCheckAllSwitch(false);
        }
        setIdsArray([...idsTmp]);
    };

    useEffect(() => {
        setListOfAllRoles(intialListOfAllRoles);
        if (openModal) {
            handleOpenModal();
        } else if (openModalEdit) {
            handleOpenModalEdit();
        } else if (openConfirmationModal) {
            //handleOpenConfirmationModal();
        } else {
            handleCloseModal();
            handleCloseModalEdit();
            handleCloseConfirmationModal();
        }
    }, [openModal]);

    // useEffect(() => {
    //     if (somethingChange) {
    //         isValidForm();
    //     }
    //     // else {
    //     //     setSomethingChange(false);
    //     // }

    // }, [somethingChange]);

    const getDtoRoles = async (rolesU: any) => {
        const responseR: any[] = [];
        const list = listOfAllRoles;
        rolesU?.forEach((role: any) => {
            let r: any = list?.filter((rolG: any) => {
                return rolG.id === role;
            });
            responseR.push({ role_name: r[0]?.role_name, id: r[0]?.id });
        });
        return responseR;
    };

    const isValidEmail = (email: string) => {
        const emailParts = email.split('@');

        if (emailParts.length === 2) {
            const domain = emailParts[1];
            // Check if the domain is in the allowed extensions
            return listOfAllAuthorizedEmails?.some((extension) => domain === extension.value);
        }
        return false;
    };
    const isValidUser = (user: any) => {
        let valid = true;

        if (!isValidString(user.first_name)) {
            setIsValidFirstNameSpan(false);
            valid = valid && false;
        } else {
            valid = valid && true;
            setIsValidFirstNameSpan(true);
        }

        if (!isValidString(user.last_name)) {
            setIsValidLastNameSpan(false);
            valid = valid && false;
        } else {
            valid = valid && true;
            setIsValidLastNameSpan(true);
        }

        if (!isValidEmail(user.email)) {
            setIsValidEmailDomainSpan(false);
            valid = valid && false;
        } else {
            valid = valid && true;
            setIsValidEmailDomainSpan(true);
        }

        if (!isValidId(user.company)) {
            setIsValidCompany(false);
            valid = valid && false;
        } else {
            valid = valid && true;
            setIsValidCompany(true);
        }

        if (!isValidId(user.country)) {
            setIsValidCountrySpan(false);
            valid = valid && false;
        } else {
            valid = valid && true;
            setIsValidCountrySpan(true);
        }

        if (!isValidMultiSelect(user.roles)) {
            setIsValidRoleSpan(false);
            valid = valid && false;
        } else {
            valid = valid && true;
            setIsValidRoleSpan(true);
        }
        // setValidForm(valid);
    };
    const getSelectDomain = (id: any) => {
        return listOfAllAuthorizedEmails?.filter((email) => email.id === id)[0]?.value;
    };
    const validateFirstName = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidFirstNameSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidFirstNameSpan(true);
        }
        return validF;
    };
    const validateLastName = (value: any) => {
        let validF2 = true;
        if (!isValidString(value)) {
            setIsValidLastNameSpan(false);
            validF2 = validF2 && false;
        } else {
            validF2 = validF2 && true;
            setIsValidLastNameSpan(true);
        }
        return validF2;
    };

    const validateEmail = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidEmailFieldSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidEmailFieldSpan(true);
        }
        return validF;
    };
    const validateEmailDomain = (value: any) => {
        let validF = true;
        if (!isValidEmail(value)) {
            setIsValidEmailDomainSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidEmailDomainSpan(true);
        }
        return validF;
    };
    const validateCompany = (value: any) => {
        let validF = true;
        if (!isValidId(value)) {
            setIsValidCompany(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidCompany(true);
        }
        return validF;
    };
    const validateCountry = (value: any) => {
        let validF = true;
        if (!isValidId(value)) {
            setIsValidCountrySpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidCountrySpan(true);
        }
        return validF;
    };
    const validateRoles = (value: any) => {
        let validF = true;
        const validSelectRoles = validateSelectRoles([...value])
        // console.log("validSelectRoles", validSelectRoles)
        if (!isValidMultiSelect(value) || validSelectRoles===false) { 
            setIsValidRoleSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true && validSelectRoles;
            setIsValidRoleSpan(true);
        }
        return validF;
    };
    const isValidForm = async () => {
        // setSomethingChange(false);
        let valid = true;
        const validations = [
            validateFirstName(firstName),
            validateLastName(lastName),
            validateEmail(email),
            validateEmailDomain(email + '@' + getSelectDomain(selectDomain)),
            validateCompany(companyID),
            validateCountry(country),
            validateRoles(roles),
        ];

        // Check all validation results
        for (const validation of validations) {
            if (!validation) {
                valid = false;
                // Don't break here to accumulate all validation errors
            }
        }
        return valid;
    };

    const columns: Column[] = [
        {
            name: 'checkbox',
            prop: 'id',
            open: false,
            show: true,
            id: 'id',
            style: { maxWidth: '50px', minWidth: '50px' },
        },
        {
            name: 'EDIT',
            prop: 'id',
            open: false,
            show: true,
            id: 1,
            style: { maxWidth: '60px', minWidth: '60px' },
            formatting: (v: any) => (
                <div
                    onClick={() => {
                        selectId(v);
                        openEditUser(v);
                    }}
                >
                    <PencilIcon className={styles.iconPencil} />{' '}
                </div>
            ),
        },
        {
            name: 'NAME',
            prop: 'name',
            show: true,
            open: false,
            id: 69,
            style: { maxWidth: '200px', minWidth: '200px' },
        },
        {
            name: 'EMAIL',
            prop: 'email',
            open: false,
            show: true,
            id: 2,
            style: { maxWidth: '200px', minWidth: '200px' },
        },
        {
            name: 'ROLE',
            prop: 'role',
            open: false,
            show: true,
            id: 3,
            style: { maxWidth: '150px', minWidth: '150px' },
            formatting: (v: any) => <div className="truncate">{hasValidValue(v) ? v : '-'} </div>,
        },
        {
            name: 'COMPANY',
            prop: 'company',
            open: false,
            show: true,
            id: 10,
            style: { maxWidth: '100px', minWidth: '100px' },
        },
        {
            name: 'DEPARTMENT',
            prop: 'department',
            open: false,
            show: true,
            id: 10,
            style: { maxWidth: '111px', minWidth: '111px' },
        },
        {
            name: 'POSITION',
            prop: 'position',
            open: false,
            show: true,
            id: 10,
            style: { maxWidth: '100px', minWidth: '100px' },
        },
        {
            name: 'COUNTRY',
            prop: 'country',
            open: false,
            show: true,
            id: 11,
            style: { maxWidth: '80px', minWidth: '80px' },
        },
        {
            name: 'LAST LOGIN',
            prop: 'last_login',
            open: false,
            show: true,
            id: 2,
            style: { maxWidth: '130px', minWidth: '130px' },
            formatting: (v: any) => (v !== 'NaN-NaN-NaN NaN:NaN' ? v : '-'),
        },
        {
            name: 'STATUS',
            prop: 'status',
            open: false,
            show: true,
            id: 3,
            style: { maxWidth: '70px', minWidth: '70px' },
            formatting: (v: any) => <div className={styles[colorByStatus(v)]}>{v} </div>,
        },
        {
            name: 'CREATED TIME',
            prop: 'created_time',
            show: true,
            open: false,
            id: 70,
            style: { maxWidth: '130px', minWidth: '130px' },
            formatting: (v: any) => (v !== 'NaN-NaN-NaN NaN:NaN' ? v : '-'),
        },
        {
            name: 'CREATED BY',
            prop: 'created_by',
            show: true,
            open: false,
            id: 71,
            style: { maxWidth: '200px', minWidth: '200px' },
        },
        {
            name: 'LAST EDITED TIME',
            prop: 'last_edited_time',
            show: true,
            open: false,
            id: 72,
            style: { maxWidth: '150px', minWidth: '150px' },
            formatting: (v: any) => (v !== 'NaN-NaN-NaN NaN:NaN' ? v : '-'),
        },
        {
            name: 'LAST EDITED BY',
            prop: 'last_edited_by',
            show: true,
            open: false,
            id: 73,
            style: { maxWidth: '130px', minWidth: '130px' },
        },
    ];

    const handleCloseModal = () => {
        setOpenModal(false);
        setRoles([]); //reset select roles to show all roles again
        const dialog = document.getElementById('add-role');
        //@ts-expect-error
        if (dialog) dialog.close();
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        const dialog = document.getElementById('add-role');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
        const dialog = document.getElementById('edit-form');
        //@ts-expect-error
        if (dialog) dialog.close();
    };

    const handleOpenModalEdit = () => {
        setOpenModalEdit(true);
        const dialog = document.getElementById('edit-form');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    const handleCloseConfirmationModal = () => {
        setOpenConfirmationModal(false);
        const dialog = document.getElementById('confirmation-modal');
        //@ts-expect-error
        if (dialog) dialog.close();
    };

    const handleOpenConfirmationModal = (id: any, type: any) => {
        setOpenConfirmationModal(true);
        setIdPassed(id);
        setTypePassed(type);
        const dialog = document.getElementById('confirmation-modal');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    const handleNewUserAction = () => {
        resetValues();
        resetFieldStates();
        setIsModalEdit(false);
        handleOpenModal();
    };

    const handleEditFormAction = () => {
        resetValues();
        resetFieldStates();
        setIsModalEdit(false);
        handleOpenModalEdit();
    };

    const handleConfirmationModalAction = (id: any, type: any) => {
        resetValues();
        resetFieldStates();
        setIsModalEdit(false);
        handleOpenConfirmationModal(id, type);
    };

    function resetFieldStates() {
        setIsValidCompany(true);
        setIsValidEmailFieldSpan(true);
        setIsValidEmailDomainSpan(true);
        setIsValidFirstNameSpan(true);
        setIsValidLastNameSpan(true);
        setIsValidRoleSpan(true);
        setIsValidCountrySpan(true);
    }
    const setDataToEdit = async (user: any) => {
        const emailS = user?.email.split('@');
        const authorizedEmail = listOfAllAuthorizedEmails?.filter(
            (emailF) => emailF.value === emailS[1]
        )[0];
        const teamLeaderD = usersData?.filter(
            (userD) => userD.id === user.teammember?.team_leader
        )[0];
        if (teamLeaderD)
            setTeamLeader([{ full_name: teamLeaderD?.full_name, id: teamLeaderD?.id }]);
        else {
            setTeamLeader([]);
        }
        const countryD: any = listOfAllCountries?.filter(
            (coun: any) => coun.id === user?.country
        )[0];

        if (countryD) setCountry(countryD.id);

        setFirstName(user?.first_name);
        setLastName(user?.last_name);
        setEmail(emailS[0]);
        setSelectDomain(authorizedEmail?.id || -1);
        setCompanyID(user?.company || -1);
        setCompanyName(user?.company_name || '');
        setDepartment(user?.department?.value || '');
        setJobPosition(user?.job_position?.value || '');
        const setedRoles = await getDtoRoles(user.roles);

        setRoles([...setedRoles]);
        setEnabledUser(!user.inactivity_status.toUpperCase().includes('DISABLE'));
        setEnabled(!user.inactivity_status.toUpperCase().includes('DISABLE') ? 1 : 0);
        isValidUser(user);
        // setDisabledState(user.inactivity_status.toUpperCase() === "INACTIVE");
    };
    const openEditUser = async (id: number) => {
        //const selectedUser = usersData?.filter((user: any) => user.id === id)[0];
        resetValues();
        const selectedUser = await getUserById(id);
        setIdsArray([id]);
        await setDataToEdit(selectedUser);
        setIsModalEdit(true);
        handleOpenModal();
    };

    const addRole: CustomButtonGroup[] = [
        {
            onClick: () => {
                handleEditFormAction();
            },
            children: (
                <>
                    <PencilSquareIcon className="h-4 w-4" /> Edit Form
                </>
            ),
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex row gap-1 items-center',
        },
        {
            onClick: () => {
                handleNewUserAction();
            },
            children: 'Add User',
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex row gap-1 items-center',
        },
    ];

    const updateUsersFunction = async () => {
        const valid = await isValidForm();
        if (valid) {
            try {
                const sD = listOfAllAuthorizedEmails?.filter((a) => a.id === selectDomain)[0];
                const payload = {
                    email: email + '@' + sD?.value,
                    is_active: enabledUser,
                    first_name: firstName,
                    last_name: lastName,
                    roles: roles.map((r) => r.id),
                    country: Number(country),
                    personal_phone: null,
                    work_phone: '',
                    team_leader: Number(teamLeader[0]?.id) || null,
                    company: companyID,
                    department: department === '' ? null : department,
                    job_position: jobPosition === '' ? null : jobPosition,
                };

                if (isModalEdit) {
                    const response = await updateUser(idsArray[0], payload);
                    if (response) {
                        Notiflix.Notify.success('User updated successfully!');
                        setLoadedData(false);
                        setSubmitSwitch(!submitSwitch);
                    } else {
                        Notiflix.Notify.failure("User Can't be updated!");
                    }
                    setIsModalEdit(false);
                    setOpenModal(false);
                    setIdsArray([]);
                } else {
                    const response = await createNewUser(payload);
                    if (response) {
                        Notiflix.Notify.success('User Created successfully!');
                        setLoadedData(false);
                        setSubmitSwitch(!submitSwitch);
                    } else {
                        Notiflix.Notify.failure("User Can't be created!");
                    }
                    setOpenModal(false);
                }
            } catch (err) {
                console.error('Error updating user', err);
                Notiflix.Notify.failure("User Can't be updated! " + err?.toString());
                // setSomethingChange(true)
            }
        }
    };

    function handleFirstNameInput(e: any) {
        setFirstName(e.target.value);
        validateFirstName(e.target.value);
        // setChangedField("first_name");
        //setSomethingChange(true);
    }

    function handleLastNameInput(e: any) {
        setLastName(e.target.value);
        validateLastName(e.target.value);
    }

    function handleEmailInput(e: any) {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    }
    function handleSelectEmail(e: any) {
        setSelectDomain(parseInt(e.target.value));
        validateEmailDomain(email + '@' + getSelectDomain(parseInt(e.target.value)));
    }

    function handleCompanyInput(e: any) {
        setCompanyName(e.target.value);
        setCompanyID(parseInt(e.target.value));
        validateCompany(e.target.value);
    }

    function handleDepartmentInput(e: any) {
        setDepartment(e.target.value);
    }

    function handleJobPositionInput(e: any) {
        setJobPosition(e.target.value);
    }

    function handleTeamLeaderInput(e: any) {
        setTeamLeader([...e]);
    }

    function handleEnabledInput(e: any) {
        setEnabledUser(e.target.checked);
    }

    function handleCountryInput(e: any) {
        setCountry(parseInt(e.target.value));
        validateCountry(parseInt(e.target.value));
    }

    const resetValues = () => {
        setRoles([]);
        handleCloseModal();
        setIdsArray([]);
        setFirstName('');
        setLastName('');
        setEmail('');
        setSelectDomain(-1);
        setCountry(-1);
        setCompanyName('');
        setDepartment('');
        setEnabled(1);
        // setDisabledState(false);
        setEnabledUser(true);
    };

    const onSelectRole = (roleS: any) => {
        const newRoles = [...roleS];
        setRoles(newRoles);
        validateRoles(roleS);
    };
    const getAddUserComponent = () => {
        return (
            <>
                <div className={styles.modalContainer}>
                    <div className={styles.flexFirst}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="">First Name</label>
                            <input
                                type="text"
                                onChange={handleFirstNameInput}
                                value={firstName}
                                required
                                className={styles.inputText}
                            />
                            {!isValidFirstNameSpan && (
                                <span className={styles.requiredEntry}>
                                    A first name is required
                                </span>
                            )}
                        </div>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="">Last Name</label>
                            <input
                                type="text"
                                onChange={handleLastNameInput}
                                value={lastName}
                                required
                                className={styles.inputText}
                            />
                            {!isValidLastNameSpan && (
                                <span className={styles.requiredEntry}>
                                    A last name is required
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={styles.flexSecondary}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="">Email</label>
                            <input
                                type="text"
                                onChange={handleEmailInput}
                                value={email}
                                className={styles.inputText}
                            />
                            {!isValidEmailFieldSpan && (
                                <span className={styles.requiredEntry}>
                                    An email entry is required required
                                </span>
                            )}
                        </div>
                        <div className={styles.arrobaSimbol}>@</div>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="domain">Domain</label>
                            {listOfAllAuthorizedEmails && listOfAllAuthorizedEmails?.length > 0 && (
                                <select
                                    className={styles.dropDown}
                                    name=""
                                    id="domain"
                                    onChange={handleSelectEmail}
                                    value={selectDomain}
                                    required
                                >
                                    <option key={'domainNull'} value={-1}>
                                        {''}
                                    </option>
                                    {listOfAllAuthorizedEmails?.map(
                                        (x) =>
                                            x.enabled === true && (
                                                <option key={'domain' + x.id} value={x.id}>
                                                    {x.value}
                                                </option>
                                            )
                                    )}
                                </select>
                            )}
                            {!isValidEmailDomainSpan && (
                                <span className={styles.requiredEntry}>
                                    An email domain selection is required
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={styles.flexSecond}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="company">Company</label>
                            <select
                                className={styles.dropDown}
                                name=""
                                id="company"
                                value={companyID}
                                onChange={handleCompanyInput}
                            >
                                <option key={'companyNull'} value={-1}>
                                    {''}
                                </option>
                                {listOfAllCompanies?.map(
                                    (x: any) =>
                                        x.enabled === true && (
                                            <option key={'company' + x.id} value={x.id}>
                                                {x.value}
                                            </option>
                                        )
                                )}
                            </select>
                            {!isValidCompany && (
                                <div className={styles.requiredEntry}>
                                    A Company selection is required
                                </div>
                            )}
                        </div>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="department">Department</label>
                            <input
                                type="text"
                                id="department"
                                onChange={handleDepartmentInput}
                                value={department}
                                required
                                className={styles.inputText}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: '30px', height: '40px' }}>
                        <label htmlFor="jobPosition">Job Position</label>
                        <input
                            type="text"
                            className={styles.inputText}
                            id="jobPosition"
                            onChange={handleJobPositionInput}
                            value={jobPosition}
                            required
                        />
                    </div>
                    <div>
                        <div style={{ position: 'relative', marginBottom: '10px' }}>
                            <label htmlFor="roles">Roles</label>
                            {listOfAllRoles && (
                                <Multiselect
                                    id="roles"
                                    placeholder="Select a role"
                                    onSelect={onSelectRole}
                                    displayValue="role_name"
                                    options={listOfAllRoles}
                                    style={stylesMultiSelect}
                                    showArrow={true}
                                    selectedValues={[...roles]}
                                    onRemove={onSelectRole}
                                />
                            )}
                            {!isValidRoleSpan && (
                                <span className={styles.requiredEntry}>Select a role with a view</span>
                            )}
                        </div>
                    </div>
                    <div className={styles.flexSecondarySelect} style={{ height: '80px' }}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="">Team Leader</label>
                            {usersData && (
                                <Multiselect
                                    disable={true}
                                    placeholder="Select a Leader"
                                    onSelect={handleTeamLeaderInput}
                                    onRemove={handleTeamLeaderInput}
                                    displayValue={'full_name'}
                                    options={usersData}
                                    singleSelect={true}
                                    selectionLimit={1}
                                    style={stylesMultiSelect}
                                    showArrow={true}
                                    selectedValues={[...teamLeader]}
                                    showCheckbox={true}
                                    avoidHighlightFirstOption={true}
                                />
                            )}
                        </div>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="">Country</label>
                            {listOfAllCountries && (
                                <select
                                    className={styles.dropDown}
                                    name=""
                                    id="jobPosition"
                                    value={country}
                                    onChange={handleCountryInput}
                                    required
                                >
                                    <option key={'domainNull'} value={-1}>
                                        {''}
                                    </option>
                                    {listOfAllCountries?.map(
                                        (x) =>
                                            x.enabled === true && (
                                                <option key={'country' + x.id} value={x.id}>
                                                    {x.name}
                                                </option>
                                            )
                                    )}
                                </select>
                            )}
                            {!isValidCountrySpan && (
                                <span className={styles.requiredEntry}>A country is required</span>
                            )}
                        </div>
                    </div>
                    <div
                        className={styles.enabledGroup}
                        style={{ justifyContent: 'flex-end', paddingBottom: '10px' }}
                    >
                        <input
                            type="checkbox"
                            onChange={handleEnabledInput}
                            value={enabled}
                            checked={enabledUser}
                        />
                        <p className={styles.enabledText}>Enabled</p>
                    </div>
                    <div className={styles.lastFlex} style={{ justifyContent: 'flex-end' }}>
                        <div className={styles.cancelSave}>
                            <button
                                type="reset"
                                className={styles.btnLight}
                                onClick={() => {
                                    resetValues();
                                }}
                            >
                                CANCEL
                            </button>
                            <button
                                type="submit"
                                className={styles.btnDark}
                                style={{ width: '90px' }}
                                onClick={async () => await updateUsersFunction()}
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const [fields, setFields] = useState(['domain', 'company', 'country']);
    const [selectedField, setSelectedField] = useState('domain');

    const handleToggleChangeDomain = async (x: any, enabled: any) => {
        const updatedEmails = listOfAllAuthorizedEmails?.map((email) =>
            email.id === x.id ? { ...email, enabled } : email
        );
        setListOfAllAuthorizedEmails(updatedEmails);
        await updateDomain(x.id, { value: x.value, enabled: enabled ? 1 : 0 });
    };
    const handleToggleChangeCompany = async (x: any, enabled: any) => {
        const updatedCompanies = listOfAllCompanies?.map((company) =>
            company.id === x.id ? { ...company, enabled } : company
        );
        setListOfAllCompanies(updatedCompanies);
        await updateCompany(x.id, { value: x.value, enabled: enabled ? 1 : 0 });
    };
    const handleToggleChangeCountry = async (x: any, enabled: any) => {
        const updatedCountries = listOfAllCountries?.map((country: any) =>
            country.id === x.id ? { ...country, enabled } : country
        );
        setListOfAllCountries(updatedCountries);
        await updateCountry(x.id, { ...x, enabled: enabled ? 1 : 0 });
    };
    async function handleChangeElement(e: any) {
        await setEnterValue(e.target.value);
    }

    const addValue = async (type: any, value: any) => {
        if (type === 'domain') {
            await createDomain({ value: value });
            const updatedDomains = await getAllAuthorizedEmails();
            setListOfAllAuthorizedEmails(updatedDomains);
        } else if (type === 'company') {
            await createCompany({ value: value });
            const updatedCompanies = await getAllCompanies();
            setListOfAllCompanies(updatedCompanies);
        } else if (type === 'country') {
            await createCountry({ name: value });
            const updatedCountries = await getAllCountries();
            setListOfAllCountries(updatedCountries);
        }

        setEnterValue('');
    };

    const deleteDomainFunction = async (id: any) => {
        try {
            await deleteDomain(id);
            const updatedDomains = await getAllAuthorizedEmails();
            setListOfAllAuthorizedEmails(updatedDomains);
        } catch (error) {
            console.error('Error deleting domain:', error);
        }
    };

    const deleteCompanyFunction = async (id: any) => {
        try {
            await deleteCompany(id);
            const updatedCompanies = await getAllCompanies();
            setListOfAllCompanies(updatedCompanies);
        } catch (error) {
            console.error('Error deleting company:', error);
        }
        handleCloseConfirmationModal();
    };

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchInputChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const filterItems = (item: any) => {
        return item.value.toLowerCase().includes(searchTerm.toLowerCase());
    };
    const filterItemsCountry = (item: any) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    };

    const deleteCountryFunction = async (id: any) => {
        try {
            await deleteCountry(id);
            const updatedCountries = await getAllCountries();
            setListOfAllCountries(updatedCountries);
        } catch (error) {
            console.error('Error deleting company:', error);
        }
    };
    const getEditFormComponent = () => {
        return (
            <div className={styles.modalContainerEdit}>
                <div className={styles.editForm}>
                    <div className={styles.optionsEditForm}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="formField">Form Field to Change</label>
                            <select
                                className={styles.dropDown}
                                id="domain"
                                onChange={(e) => setSelectedField(e.target.value)}
                                value={selectedField}
                                required
                                title="Choose a field to manipulate"
                            >
                                {fields?.map((x) => (
                                    <option key={x} value={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="">Enter a new value</label>
                            <input
                                type="text"
                                onChange={(e) => handleChangeElement(e)}
                                value={enterValue}
                                placeholder="Type value"
                                title="Type down the value you would like to add"
                                className={styles.inputText}
                            />
                        </div>
                        <button
                            type="submit"
                            className={styles.btnDark}
                            style={{ width: '120px', height: '40px' }}
                            onClick={() => enterValue !== '' && addValue(selectedField, enterValue)}
                        >
                            Add Option
                        </button>
                    </div>
                    <div
                        className={styles.listInfoContainer}
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <div style={{ position: 'relative' }}>
                            <input
                                type="text"
                                onChange={handleSearchInputChange}
                                value={searchTerm}
                                placeholder="Search"
                                style={{
                                    height: '30px',
                                    borderRadius: '5px',
                                    borderColor: '#C4C4C4',
                                    paddingRight: '26px',
                                    width: '100%',
                                    paddingLeft: '12px',
                                }}
                            />
                            <MagnifyingGlassIcon
                                className="h-4 w-4"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    right: '8px',
                                    zIndex: '1',
                                }}
                            />
                        </div>
                        <div className={styles.tableScroll}>
                            <div>
                                <div className={styles.tbody}>
                                    {selectedField === 'domain' &&
                                        listOfAllAuthorizedEmails?.filter(filterItems).map((x) => (
                                            <div key={x.id} className={styles.row}>
                                                {/* <div className={styles.listInfo}> */}
                                                <div
                                                    //className={styles.listInfo}
                                                    style={{ cursor: 'pointer' }}
                                                    title="delete domain"
                                                >
                                                    <div>
                                                        <XMarkIcon
                                                            className="h-5 w-5"
                                                            onClick={() =>
                                                                //deleteCompanyFunction(x.id)
                                                                handleConfirmationModalAction(
                                                                    x.id,
                                                                    'domain'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ cursor: 'default' }}>{x.value}</div>
                                                <div
                                                    title={
                                                        x.enabled
                                                            ? 'Click to turn off'
                                                            : 'Click to turn on'
                                                    }
                                                >
                                                    <OptionToggle
                                                        idToggle={x.id}
                                                        onChangeToggle={(v) =>
                                                            handleToggleChangeDomain(x, v)
                                                        }
                                                        value={x.enabled}
                                                        className={styles.toggleButton}
                                                        type={'edit'}
                                                    />
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        ))}
                                    {selectedField === 'company' &&
                                        listOfAllCompanies
                                            ?.filter(filterItems, 'company')
                                            .map((x) => (
                                                <div key={x.id}>
                                                    <div className={styles.listInfo}>
                                                        <div
                                                            style={{ cursor: 'pointer' }}
                                                            title="delete company"
                                                        >
                                                            <XMarkIcon
                                                                className="h-5 w-5"
                                                                onClick={() =>
                                                                    //deleteCompanyFunction(x.id)
                                                                    handleConfirmationModalAction(
                                                                        x.id,
                                                                        'company'
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div style={{ cursor: 'default' }}>
                                                            {x.value}
                                                        </div>
                                                        <div
                                                            title={
                                                                x.enabled
                                                                    ? 'Click to turn off'
                                                                    : 'Click to turn on'
                                                            }
                                                        >
                                                            <OptionToggle
                                                                idToggle={x.id}
                                                                onChangeToggle={(v) =>
                                                                    handleToggleChangeCompany(x, v)
                                                                }
                                                                value={x.enabled}
                                                                type={'edit'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    {selectedField === 'country' &&
                                        listOfAllCountries?.filter(filterItemsCountry).map((x) => (
                                            <div key={x.id}>
                                                <div className={styles.listInfo}>
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        title="delete country"
                                                    >
                                                        <XMarkIcon
                                                            className="h-5 w-5"
                                                            onClick={() =>
                                                                //deleteCompanyFunction(x.id)
                                                                handleConfirmationModalAction(
                                                                    x.id,
                                                                    'country'
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div style={{ cursor: 'default' }}>
                                                        {x.name}
                                                    </div>
                                                    <div
                                                        title={
                                                            x.enabled
                                                                ? 'Click to turn off'
                                                                : 'Click to turn on'
                                                        }
                                                    >
                                                        <OptionToggle
                                                            idToggle={x.id}
                                                            onChangeToggle={(v) =>
                                                                handleToggleChangeCountry(x, v)
                                                            }
                                                            value={x.enabled}
                                                            type={'edit'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleYesClick = (id: any, type: any) => {
        if (type === 'country') {
            deleteCountryFunction(id);
        } else if (type === 'domain') {
            deleteDomainFunction(id);
        } else if (type === 'company') {
            deleteCompanyFunction(id);
        }
        handleCloseConfirmationModal();
    };

    const getConfirmationModal = (id: any, type: any) => {
        return (
            <div className={styles.modalContainerEdit}>
                <div className={styles.confirmationModal}>
                    <h1 style={{ fontWeight: '700', fontSize: '16px', padding: '10px 0px' }}>
                        Are you sure you want to delete this value?
                    </h1>
                    <h2>This action is permanent and can not be undone.</h2>
                    <div className={styles.buttonsDelete} style={{ padding: '10px 0px' }}>
                        <div
                            onClick={() => handleYesClick(id, type)}
                            className={styles.buttonDelete}
                        >
                            Delete
                        </div>
                        <div onClick={handleCloseConfirmationModal} className={styles.buttonCancel}>
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const cancelSelections = () => {
        setIdsArray([]);
        setCheckAllSwitch(false);
    };
    const onDisableUser = async () => {
        // const holderForUsersIDS: any[] = [];
        // idsArray.forEach((element: any) => holderForUsersIDS.push(element.id));
        const response = await disableUser(idsArray);
        //    console.log("response disabled user", response)
        if (response?.detail === 'success') {
            Notiflix.Notify.success('User Disabled successfully');
            setSubmitSwitch(!submitSwitch);
        } else {
            Notiflix.Notify.failure("User can't be Disabled");
        }
        setIdsArray([]);
    };
    const getHeader = () => {
        return (
            <>
                <div className={styles.buttonsGroup}>
                    <button
                        className={
                            'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center'
                        }
                        onClick={() => resetFilters('table')}
                        type="button"
                    >
                        Reset
                    </button>
                </div>

                <div className={styles['flex-selection-options']}>
                    {idsArray && idsArray.length > 0 && (
                        <>
                            {/* <div className={styles["user-selected-length"]}>
                            {idsArray?.length}
                        </div> */}
                            <div className={styles.buttonsGroup}>
                                <button
                                    className={
                                        'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center'
                                    }
                                    onClick={() => cancelSelections()}
                                    type="button"
                                >
                                    Cancel
                                </button>
                                <button
                                    id=""
                                    className={
                                        'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center box-border -mr-.5'
                                    }
                                    type="button"
                                    onClick={() => onDisableUser()}
                                >
                                    Disable
                                </button>
                            </div>
                            {/* <input
                            id="input-search-filter"
                            type="text"
                            className={styles["form-control mr-3"]}
                            placeholder="Search"
                            onChange={(event) => updateUsersListFilter(event)}
                            autocomplete="none"
                        /> */}
                        </>
                    )}
                </div>
            </>
        );
    };
    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    const reduceColumns = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true) {
                result[column.id] = column.name;
            }
            return result;
        }, {});
        return res;
    };
    const reduceColumnTypes = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true && column.cellType !== undefined && column.cellType !== null) {
                result[column.id] = column.cellType;
            }
            return result;
        }, {});
        return res;
    };

    const exportExcel = async (columns: any) => {
        setDownLoader(true);
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = queries_glp.mainTable.query;
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp.mainTable.groupsBy,
                        name: queries_glp.mainTable.table,
                    },
                    body: queries_glp.mainTable.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        // delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoader(false);
    };

    return (
        <div className={' flex h-[98vh]'}>
            <>
                {!closedFilters && (
                    // <div id='filters_user'>
                    <FilterCard
                        close={closeFilter}
                        applyFilters={applyFilters}
                        columns={columns}
                        setUniques={setUniques}
                        filters={filtersString}
                        file={'administration_user'}
                        uniques={uniques}
                        // by="athena"
                        onReset={clearValues}
                        setExecuteApply={setExecuteApply}
                        setApplySearch={setApplySearch}
                        setCustomFilters={setCustomFilters}
                        customFilters={customFilters}
                        isColapsed={true}
                    />
                    // </div>
                )}
                <div className={closedFilters ? 'w-full ' : 'w-[80%] '}>
                    <div>
                        {closedFilters && (
                            <div className={styles.filterButton}>
                                <FilterButton
                                    className="hidden"
                                    close={closeFilter}
                                    top={154}
                                    regularFormat={true}
                                />
                            </div>
                        )}
                        {allRows && allFilteredRows && loadedData && (
                            <Table
                                title={'Users Management'}
                                columns={columns}
                                data={allFilteredRows}
                                initialData={allRows}
                                fetchData={initialDataCall}
                                haveMoreItems={false}
                                updateData={function (data: any): void {
                                    // console.log("updateData", data)
                                    setAllFilteredRows(data);
                                }}
                                count={allFilteredRows?.length}
                                searchText=""
                                columnsButton={true}
                                resetButton={false}
                                isSearch={true}
                                toolsNative={true}
                                hasTotal={true}
                                allColumnsStyle=" w-full "
                                selectId={selectId}
                                selectAll={selectAll}
                                selectAllBoolean={checkAllSwitch}
                                idsArray={idsArray}
                                customButton={addRole}
                                hasAdditionalHeader={true}
                                additionalHeader={getHeader}
                                isLoadingData={!loadedData}
                                // exportLoader={downLoader}
                                // exportData={exportExcel}
                                lightTheme={true}
                                // onReset={clearValues}
                                // filter={
                                //     {
                                //         applyFilters: applyFilters,
                                //         columns: columns,
                                //         setUniques: setUniques,
                                //         filters: filtersString,
                                //         file: 'administration_user',
                                //         uniques: uniques,
                                //         // by="athena"
                                //         onReset: clearValues,
                                //         setExecuteApply: setExecuteApply,
                                //         setApplySearch: setApplySearch,
                                //         setCustomFilters: setCustomFilters,
                                //         customFilters: customFilters,
                                //     }
                                // }
                            ></Table>
                        )}
                        <GenericModal
                            className="confirmationModal"
                            closeDialog={handleCloseConfirmationModal}
                            haveComponent={true}
                            getComponent={getConfirmationModal(idPassed, typePasssed)}
                            name={'confirmation-modal'}
                            title={'DELETE VALUE'}
                        ></GenericModal>
                        {loadedData && (openModalEdit || openModal) && (
                            <>
                                <GenericModal
                                    className="addUser"
                                    closeDialog={handleCloseModal}
                                    haveComponent={true}
                                    getComponent={getAddUserComponent()}
                                    name={'add-role'}
                                    isClosable={false}
                                ></GenericModal>
                                <GenericModal
                                    className="editForm"
                                    closeDialog={handleCloseModalEdit}
                                    haveComponent={true}
                                    getComponent={getEditFormComponent()}
                                    name={'edit-form'}
                                    title={'EDIT FORM'}
                                    isClosable={false}
                                ></GenericModal>
                            </>
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};
